<template>
  <div id="story">
    <Nav/>
    <main v-if="story != ''">
      <header>
        <img :src="story.image1">
      </header>
      <main>
        <h2>
          {{ story.name }}
        </h2>
        <p v-html="story.report">
        </p>
        <a>
          我要破案
        </a>
      </main>

      <aside class="record">
        <h2>
          偵辦狀況
        </h2>
        <main>
          <div>
            <h4> {{ story.solve }} </h4>
            <p>位實習判官已破案</p>
          </div>
          <div>
            <h4> {{ story.solve_rate * 100 }}%</h4>
            <p>破關率</p>
          </div>
          <div>
            <h4> {{ story.solve_time }} </h4>
            <p>破案時間（分鐘）</p>
          </div>
        </main>
        <a href="https://line.me/ti/p/@183sknuo" target="_blank">
          加入挑戰
        </a>
      </aside>
    </main>
  </div>
</template>

<script>
import Nav from "@/components/nav";
import axios from 'axios';

export default {
  components: {
    Nav
  },
  data(){
    return{
      story: ""
    }
  },
  beforeMount(){
    axios.get("https://judicial-intern.dmmt.design/api/v1/stories/" + this.$route.params.id)
    .then(response => {
      this.init(response.data)
    }).catch(error=> {
      console.log(error)
    })
  },
  methods: {
    init(data){
      this.story = data.story
    },
    truncate(text){
      if (text.length > 150) {
          return text.substring(0, 150) + "....";
      } else {
          return text;
      }
    }
  }
}
</script>

<style scoped lang="scss">
#story{
  > main {
    max-width: 960px;
    margin: 90px auto;
    width: 90%;

    header{
      img{
        width: 100%;
      }
    }
    h2{
      font-size: 36px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #333333;
      margin-top: 42px;
    }
    > main{
      p{
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #999999;
        margin-top: 18px;
      }
      a{
        display: block;
        width: 115px;
        text-align: center;
        padding: 13px 26px 13px 25px;
        background-color: #000000;
        color: #fff;
        font-size: 16px;
        margin: 18px auto;
      }
    }
    
    aside.record{
      main{
        display: flex;
        justify-content: space-around;
        padding-top: 30px;
        div{
          text-align: center;
          h4{
            font-size: 3em;
            color: #666666;
            padding-bottom: 20px;
          }
          p{
            font-size: 16px;
            color: #666666;
            text-align: center;
          }
        }
      }
      a{
        display: block;
        width: 115px;
        text-align: center;
        padding: 13px 26px 13px 25px;
        background-color: #000000;
        color: #fff;
        font-size: 16px;
        margin: 18px auto;
      }
    }
  }
}
</style>
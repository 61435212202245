<template>
  <div id="case-storage">
    <main v-if="this.line_id != ''" class="content">
      <div>
        <div class="profile">
          <div class="title">收藏庫</div>
          <hr class="hr"/>
          <div class="series">聖誕傳情卡片</div>
          <div v-for="christmasCard in christmasCards" :key='christmasCard.key' class="cardtemplate">
              <span class="cards-title">
                {{convertJudgeName(christmasCard.name)}}
              </span>
              <div class="cards">
                <div v-for='card in christmasCard.cards' :key='card.key'>
                  <img v-bind:src='card.url' v-if="card.own" @click="renderedImageToChatbot(card.url)" />
                  <img v-bind:src='card.not_own_url' v-else />
                </div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <main v-else>
      <div style="text-align: center; font-size: 24px; margin-top: 20px;">
        資料載入中
      </div>
    </main>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      judge: this.$route.params.judge,
      line_id: '',
      christmasCards: []
    };
  },
  async beforeMount() {
    await window.liff.init({ liffId: "1653951718-Zr0DWB7z" })
    const context = window.liff.getContext();
    // this.getProfileData('U016222f6eeee6bc349074ce00e46aa37')
    this.getProfileData(context.userId);
    // if (localStorage.lineId) {
    //   this.getProfileData(localStorage.lineId);
    // } else {
    //   window.liff.getProfile().then(profile => {
    //     localStorage.lineId = profile.userId;
    //     this.getProfileData(profile.userId);
    //   }).catch((err) => {
    //     console.log(`error ${err}`)
    //   });
    // }
  },
  methods: {
    getProfileData(line_id){
      axios.get("https://judicial-intern.dmmt.design/api/v1/line/profile",{
        params: { line_id: line_id }
      })
      .then(response => {
        // console.log('response', response)
        this.init(response.data)
      }).catch(error=> {
        console.log(error)
      })
      axios.get(`https://judicial-intern.dmmt.design/api/v2/members/${line_id}/topics`)
           .then(response => { (this.topics = response.data.topics) && (console.log(this.topics)) })
    },
    init(data) {
      this.line_id = data.member.id;
      this.christmasCards = data.member.christmas_cards
    },
    convertJudgeName(judge_key) {
      if (judge_key === 'exhibit') { return '<<魅嵐>>'; }
      else if (judge_key === 'related') { return '<<冥鏡>>'; }
      else if (judge_key === 'soul') { return '<<靈陽>>'; }
      else if (judge_key === 'timeline') { return '<<幽倩>>'; }
      else if (judge_key === 'hint') { return '<<小石獅>>'; }
      else { return '<<小閻王>>'; }
    },
    renderedImageToChatbot(card_url) {
      axios.get("https://judicial-intern.dmmt.design/api/v1/line/image?image_url="+ card_url + "&line_id=" + this.line_id)
      window.liff.closeWindow();
    }
  },
};
</script>

<style lang="scss" src="./case-storage.scss" scoped></style>

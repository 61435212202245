import axios from 'axios';
import { getters, mutations } from "@/components/game/store";
import GameNotification from "@/components/game/shared/notification";
import BaseBanner, { bannerProps } from "@/components/game/shared/baseBanner";
import Search from "@/components/game/shared/search"
import eventBus from "@/components/game/store/eventBus";
import { copyContent } from '@/components/share/copy.js';

export default {
  components: {
    GameNotification, BaseBanner, Search
  },
  props: ['memberLineId', 'memberName', 'groupId', 'updateGroupStoryShip', 'updateFinishedDemo'],
  data() {
    return {
      judge: this.$route.params.judge,
      defaultExhibits: [],
      foundExhibits: [],
      selectExhibit: {},
      story_id: "",
      selectExhibitCate: "default",
      found: true,
      progress: [],
      totalExhibitNum: "",
      foundTotalExhibitNum: "",
      useToolPopup: false,
      toolNum: 0,
      tutorialMode: false,
      currentTutorialStep: 'searchExhibit',
      tutorialSteps: ['searchExhibit', 'foundFirstExhibit', 'showFirstFoundExhibitDetail',
                      'showExhibitSize', 'finished'],
      sendSearchForm: false,
      hasInitDone: false,
      icon: {
        search: '',
        exhibitDefault: '',
        exhibitDefaultActive: '',
        exhibitDefaultBG: '',
        exhibitFound: '',
        exhibitFoundActive: '',
        exhibitFoundBg: '',
        tool: '',
        default_exhibit_bg_color: '',
        found_exhibit_bg_color: ''
      },
      background: {
        mainBg: ""
      },
      bannerProps: {...bannerProps}
    };
  },
  watch: {
    allInitDone: {
      handler(isDone) {
        isDone ? mutations.closeLoading() : mutations.openLoading()
      },
      immediate: true
    }
  },
  computed: {
    allInitDone() {
      return this.hasInitDone
    },
    mainContentBg() {
      return this.selectExhibitCate === 'default' ? this.icon.exhibitDefaultBG : this.icon.exhibitFoundBg
    },
    computedDefaultStyles () {
      const isDefault = this.selectExhibitCate === 'default'
      return {
        backgroundColor: isDefault && this.foundExhibits.length > 0 ? this.icon.found_exhibit_bg_color : ''
      }
    },
    computedFoundStyles () {
      const isDefault = this.selectExhibitCate === 'default'
      return {
        backgroundColor: !isDefault ? this.icon.default_exhibit_bg_color : ''
      }
    }
  },
  async created() {
    this.initViewData();
    await axios.get(`https://judicial-intern.dmmt.design/api/v2/groups/${this.groupId || this.memberLineId}/exhibits?line_id=${this.memberLineId}`)
      .then(async response => {
        this.init(response.data)
        await this.getToolNum()
      }).catch(error => {
        console.log(`遊戲服務中斷: ${error.message}`)
      })
  },
  mounted () {
    eventBus.$on('received', (itemType, item) => {
      this.updateReceivedHadFoundItem(itemType, item)
    });
  },
  beforeDestroy () {
    eventBus.$off('received')
  },
  methods: {
    initViewData() {
      const topicInfo = getters.getTopicInfo('exhibit')
      this.bannerProps.imgSrc = topicInfo.banner
      this.bannerProps.content = this.sampleContent(topicInfo.banner_content)
      this.bannerProps.banner_avatar = topicInfo.banner_avatar
      this.bannerProps.styles = "top: 50px; left: 155px;"
      this.icon.search = topicInfo.tool
      this.icon.exhibitDefault = topicInfo.default_exhibit
      this.icon.exhibitDefaultActive = topicInfo.default_exhibit_active
      this.icon.exhibitDefaultBG = topicInfo.default_exhibit_background
      this.icon.exhibitFound = topicInfo.found_exhibit
      this.icon.exhibitFoundActive = topicInfo.found_exhibit_active
      this.icon.exhibitFoundBg = topicInfo.found_exhibit_background
      this.icon.tool = topicInfo.sum
      this.icon.default_exhibit_bg_color = topicInfo.default_exhibit_bg_color
      this.icon.found_exhibit_bg_color = topicInfo.found_exhibit_bg_color
      this.background.mainBg = topicInfo.background
    },
    async userTool() {
      await this.getToolNum()
      this.useToolPopup = true
    },
    init(data) {
      this.story_id = data.group.story_id
      this.progress = data.progress
      this.exhibits = data.exhibits
      this.defaultExhibits = data.exhibits.filter(exhibit => exhibit.status === 'default')
      this.foundExhibits = data.exhibits.filter(exhibit => exhibit.status === 'found' && data.progress.includes(exhibit.id))
                               .sort((a, b) => this.progress.indexOf(a.id) - this.progress.indexOf(b.id))
      this.selectExhibit = this.defaultExhibits[0] || {};
      this.currentTutorialStep = (data.member.task.currentTutorialStep ? data.member.task.currentTutorialStep : 'searchExhibit');
      this.tutorialMode = (data.story.tutorialMode && this.currentTutorialStep !== 'finished');
      this.totalExhibitNum = data.exhibits.length;
      this.foundTotalExhibitNum = data.progress.length;
      if (data.demo) { this.updateFinishedDemo(); }
    },
    getToolNum() {
      return axios.get("https://judicial-intern.dmmt.design/api/v1/line/get_tool_num?line_id=" + this.memberLineId)
                  .then(response => { this.setToolNum(response.data.tool_num) })
                  .catch(error => { console.log(error) })
    },
    setToolNum(num) {
      this.toolNum = num || 0;
    },
    searchExhibit(queryText) {
      if (this.sendSearchForm) { return; }

      this.sendSearchForm = true
      let targetExhibit = this.exhibits.find(exhibit => exhibit.name === queryText || exhibit.keywords.includes(queryText));
      if (this.tutorialMode && targetExhibit.name === '彩色DIY手環') {
        this.nextTutorialStep();
      }
      if (this.progress.includes(targetExhibit?.id) || targetExhibit?.status === 'default') {
        this.selectExhibit = targetExhibit
        this.selectExhibitCate = targetExhibit.status
        this.sendSearchForm = false
        return;
      }

      this.updateGroupStoryShip('exhibit', queryText);
      this.sendSearchForm = false
      if (!targetExhibit) { return; }

      this.updatedFoundList(targetExhibit)
      this.selectExhibit = targetExhibit
      this.selectExhibitCate = 'found'
      if (this.foundTotalExhibitNum === this.totalExhibitNum) {
        window.liff.sendMessages([{ type: 'text', text: "我發現全部證物了！！" }]);
      }
    },
    updatedFoundList(newItem){
      if (this.progress.includes(newItem.id)) { return; }

      this.progress.push(newItem.id)
      this.foundExhibits.push(newItem)
      this.foundTotalExhibitNum += 1
    },
    updateReceivedHadFoundItem(itemType, item) {
      if (!item) { return; }
      if (itemType !== 'hadFoundExhibitIds') { return; }

      this.updatedFoundList(item);
    },
    async nextTutorialStep() {
      const currentTutorialStepIndex = this.tutorialSteps.indexOf(this.currentTutorialStep);
      this.currentTutorialStep = this.tutorialSteps[currentTutorialStepIndex + 1];
      await axios.put('https://judicial-intern.dmmt.design/api/v3/tutorial_tasks/判官頁面流程操作',
        {
          line_id: this.memberLineId, group_token: this.groupId || this.memberLineId, page: 'exhibits',
          progress: this.currentTutorialStep
        });
      if (this.currentTutorialStep === 'finished') {
        window.liff.closeWindow();
      }
    },
    changeLine(content) {
      if (!content) { return; }

      return content.replaceAll('\n', '<br>');
    },
    sampleContent(contents) {
      return contents[Math.floor(Math.random() * contents.length)]
    },
    setSelectExhibit(cate, tag) {
      this.found = true
      this.selectExhibitCate = cate
      if (cate == "default") {
        this.selectExhibit = this.defaultExhibits.find(exhibit => exhibit.tag == tag)
      } else {
        this.selectExhibit = this.foundExhibits.find(exhibit => exhibit.tag == tag)
      }
    },
    // accept() {
    //   axios.get("https://judicial-intern.dmmt.design/api/v1/line/use_tool",
    //     { params: { group_id: this.groupId, story_id: this.story_id, tool: "cate", line_id: this.memberLineId } }
    //   )
    //     .then(response => {
    //       this.useTool(response.data)
    //     }).catch(error => {
    //       console.log(error)
    //     })
    // },
    async sharedOwnExhibits() {
      if (this.foundExhibits.length === 0) {
        this.$buefy.notification.open({
          message: '哎呀！你沒找到任何證物，別開我玩笑了！',
          type: 'is-warning',
          position: 'is-bottom-right'
        });
      } else {
        const foundExhibitNames = this.foundExhibits.map(e => e.name);
        let copyText = `嗨，判官\n以下是魅嵐幫你整理好的證物：\n${foundExhibitNames.join(', ')}`;
        if ((foundExhibitNames.length + this.defaultExhibits.length) < this.exhibits.length) {
          copyText += `\n\n本案尚有： ${this.exhibits.length - this.defaultExhibits.length - foundExhibitNames.length} 個證物未被找到`;
        }
        copyContent(copyText, '複製成功，快去分享證物吧～');
      }
    },
    async accept() {
      try {
        const response = (await axios.post(
          `https://judicial-intern.dmmt.design/api/v3/stories/${this.story_id}/unlock_exhibits`,
          { groupId: this.groupId, memberLineId: this.memberLineId }
        )).data;
        console.log(response);
        if (response.success) {
          this.toolNum = response.cakeNumber;
          this.useToolPopup = false;
          this.searchExhibit(response.exhibitName);
        }
      } catch (e) {
        alert(e);
      }

      // if (data.success) {
        
      //   this.toolNum = data.tool_num;
      //   try {
      //     await window.liff.sendMessages([{
      //       type: 'text',
      //       text: "把草莓蛋糕交給魅嵐"
      //     }]);
      //     window.liff.closeWindow()
      //   } catch (e) {
      //     console.log(e);
      //   }
      // }
    },
    onLoaded () {
      this.hasInitDone = true
    }
  }
};

<template>
  <aside class="banner" v-if="stories.length > 0">
    <b-carousel>
      <b-carousel-item v-for="(story, i) in stories" :key="`banner-${i}`">
        <a @click="showStoryModal(story)">
          <b-image class="image" :src="story.coverImage">
            {{story.name}}
          </b-image>
          <h5>{{story.name}}</h5>
        </a>
      </b-carousel-item>
    </b-carousel>
  </aside>
</template>
<script>
  const STORY_URL = 'https://judicial-intern.dmmt.design/api/v4/stories?storyTagId=26';

  import axios from "axios";

  export default {
    props: [ 'showStoryModal'],
    data() {
      return {
        stories: []
      }
    },
    async created() {
      const res = await axios.get(STORY_URL);
      this.stories = res.data.stories[0].stories;
    }
  }
</script>

<style scoped lang="scss">
  .banner {
    margin: 24px auto;
    max-width: 520px;

    .carousel {
      .carousel-items {
        .carousel-item {
          a {
            position: relative;

            h5 {
              position: absolute;
              top: 5px;
              color: #fff;
              font-weight: 900;
              font-size: 18px;
              padding: 4px 16px;
            }
          }
        }
      }
    }
  }
</style>

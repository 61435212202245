import axios from "axios";
import { getters, mutations } from "@/components/game/store";
import BaseBanner, { bannerProps } from "@/components/game/shared/baseBanner";


export default {
  components: {
    BaseBanner
  },
  props: ['memberLineId', 'memberName', 'groupId'],
  data() {
    return {
      judge: this.$route.params.judge,
      allHints: [],
      icon: {
        hintBlock: "",
        unlockBlock: "",
        locked: "",
      },
      background: {
        mainBg: ""
      },
      bannerProps: {...bannerProps}
    };
  },
  async created() {
    this.initViewData();
    await axios
      .get(
        "https://judicial-intern.dmmt.design/api/v1/line/hints?group_id=" +
          this.groupId
      )
      .then((response) => {
        this.init(response.data);
      })
      .catch((error) => {
        console.log(error);
        console.log(`遊戲服務中斷: ${error.message}`)
      });
  },
  methods: {
    initViewData() {
      const topicInfo = getters.getTopicInfo('hint')
      this.bannerProps.imgSrc = topicInfo.banner
      this.bannerProps.content = this.sampleContent(topicInfo.banner_content)
      this.bannerProps.styles = "top: 50px; left: 155px;"
      this.bannerProps.banner_avatar = topicInfo.banner_avatar
      this.icon.hintBlock = topicInfo.unlocked
      this.icon.unlockBlock = topicInfo.unlocked_background
      this.icon.locked = topicInfo.locked
      this.background.mainBg = topicInfo.background
    },
    sampleContent(contents) {
      return contents[Math.floor(Math.random() * contents.length)];
    },
    init(data) {
      this.story_id = data.group?.story_id;
      this.allHints = data.hints || this.allHints;
    },
    revealOneHint() {
      axios
        .get(
          "https://judicial-intern.dmmt.design/api/v1/line/hint?group_id=" +
            this.groupId,
          { params: { group_id: this.groupId, story_id: this.story_id } }
        )
        .then((response) => {
          this.setHintResult(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    changeLine(content) {
      var temp_content = "";
      if (content) {
        content.split("\n").forEach(function (e) {
          temp_content = temp_content + e + "<br>";
        });
        return temp_content;
      }
    },
    setHintResult(data) {
      this.allHints.find((hint) => hint.id == data.hint.id).is_used = true;
      this.allHints.find((hint) => hint.id == data.hint.id).content =
        data.hint.report;
    },
    onLoaded () {
      mutations.closeLoading()
    }
  },
};
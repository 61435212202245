import axios from 'axios';
import GameNotification from "@/components/game/shared/notification";
import SelectedOptions from "@/components/game/pages/times/options";
import { getters, mutations } from "@/components/game/store";
import BaseBanner, { bannerProps } from "@/components/game/shared/baseBanner";
import Vue from 'vue';
import eventBus from "@/components/game/store/eventBus";

export default {
  components: {
    GameNotification,
    BaseBanner,
    SelectedOptions
  },
  props: ['memberLineId', 'memberName', 'groupId', 'updateGroupStoryShip', 'updateFinishedDemo'],
  data() {
    return {
      judge: this.$route.params.judge,
      storyId: '',
      hasConnection: false,
      timelines: [],
      currentTimeline: {},
      hadFoundExhibitIds: [],
      hadFoundRelatedIds: [],
      hadFoundTimelineIds: [],
      hadFoundPositionIds: [],
      translateItemToHadFound: {
        preExhId: 'hadFoundExhibitIds',
        preRelId: 'hadFoundRelatedIds',
        preTimeId: 'hadFoundTimelineIds',
        prePosId: 'hadFoundPositionIds'
      },
      isCardModalActive: false,
      progress: [],
      icon: {
        lockTime: "",
        findClue: ""
      },
      background: {
        mainBg: ""
      },
      bannerProps: {...bannerProps}
    };
  },
  async created(){
    await axios.get(`https://judicial-intern.dmmt.design/api/v2/groups/${this.groupId || this.memberLineId}/timelines?line_id=${this.memberLineId}`)
      .then(response => {
        this.initViewData()
        this.init(response.data)
      }).catch(error=> {
        console.log(error)
        console.log(`遊戲服務中斷: ${error.message}`)
      })
  },
  mounted() {
    eventBus.$on('received', (itemType, item) => {
      this.updateReceivedHadFoundItem(itemType, item);
    });
  },
  beforeDestroy() {
    eventBus.$off('received')
  },
  methods: {
    initViewData () {
      const topicInfo = getters.getTopicInfo('timeline')
      this.bannerProps.imgSrc = topicInfo.banner
      this.bannerProps.content = this.sampleContent(topicInfo.banner_content)
      this.bannerProps.styles = "top: 50px; left: 155px;"
      this.bannerProps.banner_avatar = topicInfo.banner_avatar
      Vue.set(this.icon, 'line', topicInfo.line)
      Vue.set(this.icon, 'node', topicInfo.node)
      Vue.set(this.icon, 'node_locked', topicInfo.node_locked)
      Vue.set(this.icon, 'lockTime', topicInfo.locked_text)
      Vue.set(this.icon, 'findClue', topicInfo.search)
      this.background.mainBg = topicInfo.background
    },
    sampleContent(contents) {
      return contents[Math.floor(Math.random() * contents.length)]
    },
    timelineContent(timeline){
      if(this.progress.includes(timeline.id) && this.isUnlocked(timeline) && timeline.result != ""){
        return this.changeLine(timeline.result)
      } else {
        return this.changeLine(timeline.report)
      }
    },
    changeLine(content){
      if (!content) { return; }

      return content.replaceAll('\n', '<br>');
    },
    init(data){
      this.timelines = data.timelines
      this.story_id = data.group.story_id
      this.progress = data.progress
      this.hadFoundExhibitIds = data.hadFound.exhibitIds;
      this.hadFoundRelatedIds = data.hadFound.relatedIds;
      this.hadFoundPositionIds = data.hadFound.positionIds;
      this.hadFoundTimelineIds = data.hadFound.timelineIds;
      if(data.demo){ this.updateFinishedDemo(); }
    },
    isUnlocked(timeline) {
      const isUnlocked = Object.keys(this.translateItemToHadFound).every(lockItem => {
        if (timeline[lockItem].length === 0) { return true; }

        return timeline[lockItem].every(lock => {
          return this[this.translateItemToHadFound[lockItem]].includes(lock);
        })
      })
      return isUnlocked;
    },
    updatedFoundList(newItem){
      if (this.progress.includes(newItem.id)) { return; }

      this.progress.push(newItem.id)
    },
    updateReceivedHadFoundItem(itemType, item) {
      if (!item) { return; }

      this[itemType].push(item.id);
      if (itemType === 'hadFoundTimelineIds') {
        this.updatedFoundList(item);
      }
    },
    async findClue(index){
      this.currentTimeline = this.timelines[index];
      this.isCardModalActive = true;
    },
    async selectedOption (option) {
      this.isCardModalActive = false;
      this.updateGroupStoryShip('timeline', this.currentTimeline.eventTime, { timeline_option: option });
    },
    hideOptions () {
      this.isCardModalActive = false;
    },
    onLoaded () {
      mutations.closeLoading()
    }
  }
};
